<template>
  <router-view/>
</template>

<style lang="stylus">
  #app {
    position relative;
    width 100%;
    height 100%;
  }
</style>