import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main.vue'),
    children: [
      // 首页
      {
        path: "/home",
        name: "home",
        component: () =>
          import ("../views/home/index")
      },
      // 会员
      {
        path: "/member",
        name: "member",
        component: () =>
          import ("../views/member/member")
      },
      // 商品
      {
        path: "/category",
        name: "category",
        component: () =>
          import ("../views/product/category")
      },
      {
        path: "/product",
        name: "product",
        component: () =>
          import ("../views/product/product")
      },
      {
        path: "/productstock",
        name: "productstock",
        component: () =>
          import ("../views/product/stock")
      },
      {
        path: "/salemanage",
        name: "salemanage",
        component: () =>
          import ("../views/product/sale-manage")
      },
      // 促销
      {
        path: "/discount",
        name: "discount",
        component: () =>
          import ("../views/promotion/discount")
      },
      // 配送
      {
        path: "/deliveryman",
        name: "deliveryman",
        component: () =>
          import ("../views/delivery/delivery-man")
      },
      {
        path: "/deliverylist",
        name: "deliverylist",
        component: () =>
          import ("../views/delivery/delivery-list")
      },
      // 订单
      {
        path: "/order",
        name: "order",
        component: () =>
          import ("../views/order/order")
      },
      {
        path: "/refund",
        name: "refund",
        component: () =>
          import ("../views/order/refund")
      },
      // 报表
      {
        path: "/dailyreport",
        name: "dailyreport",
        component: () =>
          import ("../views/report/daily-report")
      },
      {
        path: "/monthlyreport",
        name: "monthlyreport",
        component: () =>
          import ("../views/report/monthly-report")
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
